import { FetchResult } from "@apollo/client";
import {
  AppUser,
  CreateAppUserMutation,
  CreateUserRoleMutation,
  DeleteAppUserMutation,
  DeleteUserRoleMutation,
  UpdateUserMutation,
  User,
  UserRole,
} from "../../../apollo/customQueries/nested/API";
import { MutationNodeCallback } from "../../../types/types";
import { areSetsEqual } from "../../../utils/sets";
import { showError } from "../../../utils/toastNotifications";
import { UserFormData } from "../types";

async function applyUserChanges(
  user: User,
  formData: UserFormData,
  updateUserMutation: MutationNodeCallback<NonNullable<UpdateUserMutation>>,
) {
  if (user.lastName !== formData.lastName || user.firstName !== formData.firstName || user.preferredName !== formData.preferredName) {
    const input = {
      id: user.id,
      email: user.email,
      lastName: formData.lastName,
      firstName: formData.firstName,
      preferredName: formData.preferredName,
    };
    await updateUserMutation({ variables: { input } });
  }
}

async function applyAppsChanges(
  user: User,
  formData: UserFormData,
  createAppUser: MutationNodeCallback<NonNullable<CreateAppUserMutation>>,
  deleteAppUser: MutationNodeCallback<NonNullable<DeleteAppUserMutation>>,
) {
  const appUsers = (user?.apps?.items ?? []) as AppUser[];
  const currentAppIds = new Set(appUsers?.map((userApp) => userApp?.app?.id) ?? []);
  const requestedAppIds = new Set(formData.apps);

  if (!areSetsEqual(currentAppIds, requestedAppIds)) {
    const createRequests: Promise<FetchResult<CreateAppUserMutation>>[] = [...requestedAppIds].map((id) =>
      createAppUser({ variables: { input: { appID: id, userID: user.email ?? "" } } }),
    );

    const deleteRequests: Promise<FetchResult<DeleteAppUserMutation>>[] = appUsers.map((appUser) =>
      deleteAppUser({ variables: { input: { id: appUser.id } } }),
    );

    await Promise.all([...createRequests, ...deleteRequests]);
  }
}

async function applyRolesChanges(
  user: User,
  formData: UserFormData,
  createUserRole: MutationNodeCallback<NonNullable<CreateUserRoleMutation>>,
  deleteUserRole: MutationNodeCallback<NonNullable<DeleteUserRoleMutation>>,
) {
  const userRoles = (user?.roles?.items ?? []) as UserRole[];
  const currentRoleIds = new Set(userRoles?.map((userRole) => userRole?.role?.id) ?? []);
  const requestedRoleIds = new Set([formData.role]);

  if (!areSetsEqual(currentRoleIds, requestedRoleIds)) {
    const createRequests: Promise<FetchResult<CreateUserRoleMutation>>[] = [...requestedRoleIds].map((id) =>
      createUserRole({ variables: { input: { roleID: id, userID: user.email ?? "" } } }),
    );

    const deleteRequests: Promise<FetchResult<DeleteUserRoleMutation>>[] =
      userRoles?.map((userRole) => deleteUserRole({ variables: { input: { id: userRole.id } } })) ?? [];

    await Promise.all([...createRequests, ...deleteRequests]);
  }
}

export async function applyChanges(
  user: User,
  formData: UserFormData,
  updateUserMutation: MutationNodeCallback<NonNullable<UpdateUserMutation>>,
  createAppUser: MutationNodeCallback<NonNullable<CreateAppUserMutation>>,
  deleteAppUser: MutationNodeCallback<NonNullable<DeleteAppUserMutation>>,
  createUserRole: MutationNodeCallback<NonNullable<CreateUserRoleMutation>>,
  deleteUserRole: MutationNodeCallback<NonNullable<DeleteUserRoleMutation>>,
): Promise<boolean> {
  try {
    await Promise.all([
      applyUserChanges(user, formData, updateUserMutation),
      applyAppsChanges(user, formData, createAppUser, deleteAppUser),
      applyRolesChanges(user, formData, createUserRole, deleteUserRole),
    ]);
    return true;
  } catch (e) {
    showError("Something went wrong. Cannot apply changes to the user");
    return false;
  }
}
