import { FetchResult } from "@apollo/client";
import {
  AppRole,
  DeleteAppRoleMutation,
  DeletePermissionRoleMutation,
  DeleteRoleMutation,
  DeleteUserRoleMutation,
  PermissionRole,
  Role,
  UserRole,
} from "../../apollo/customQueries/nested/API";
import { MutationNodeCallback } from "../../types/types";

export async function deleteSelectedRole(
  role: Role,
  deleteRoleMutation: MutationNodeCallback<NonNullable<DeleteRoleMutation>>,
  deleteAppRole: MutationNodeCallback<NonNullable<DeleteAppRoleMutation>>,
  deletePermissionRole: MutationNodeCallback<NonNullable<DeletePermissionRoleMutation>>,
  deleteUserRole: MutationNodeCallback<NonNullable<DeleteUserRoleMutation>>,
): Promise<void> {
  if (!role?.id) {
    return Promise.resolve();
  }
  try {
    // delete appRole connections
    const appRoles = (role.apps?.items ?? []) as AppRole[];
    const deleteAppRoleRequests: Promise<FetchResult<DeleteAppRoleMutation>>[] = appRoles.map((appRole) =>
      deleteAppRole({ variables: { input: { id: appRole.id } } }),
    );

    // delete permissionRole connections
    const permissionRoles = (role.permissions?.items ?? []) as PermissionRole[];

    const deletePermissionRoleRequests: Promise<FetchResult<DeletePermissionRoleMutation>>[] = permissionRoles.map((permissionRole) =>
      deletePermissionRole({ variables: { input: { id: permissionRole.id } } }),
    );

    // delete userRole connections
    const userRoles = (role.users?.items ?? []) as UserRole[];
    const deleteUserRoleRequests: Promise<FetchResult<DeleteUserRoleMutation>>[] = userRoles.map((userRole) =>
      deleteUserRole({ variables: { input: { id: userRole.id } } }),
    );

    await Promise.all([...deleteAppRoleRequests, ...deletePermissionRoleRequests, ...deleteUserRoleRequests]);

    // finally, delete the role
    await deleteRoleMutation({ variables: { input: { id: role.id ?? "" } } });

    return Promise.resolve();
  } catch (e) {
    console.log("Failed to delete role:", e);
    return Promise.reject();
  }
}
