import { UnlockTwoTone } from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import React from "react";
import { useAuthContext } from "../../auth/provider";
// import { useHeaderContext, games } from "./context";
import css from "./index.module.scss";

function Header() {
  const { user, logout } = useAuthContext();
  // const { game, setGame } = useHeaderContext();

  return (
    <div className={css.container}>
      {/* TODO uncomment when game is available*/}
      {/*<Select value={game} onChange={setGame}>*/}
      {/*  {games.map((option: string) => (*/}
      {/*    <Select.Option key={option} value={option}>*/}
      {/*      {option ?? ""}*/}
      {/*    </Select.Option>*/}
      {/*  ))}*/}
      {/*</Select>*/}
      {user.name}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="logout" onClick={logout}>
              <UnlockTwoTone /> Logout
            </Menu.Item>
          </Menu>
        }
      >
        <Avatar src={user.imageUrl} style={{ cursor: "pointer" }} />
      </Dropdown>
    </div>
  );
}

export default Header;
