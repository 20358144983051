import { Card, Space, Row, Typography, Button } from "antd";
import React from "react";
import { useAuthContext } from "../provider";
import css from "./index.module.scss";

function LoginPage() {
  const { login } = useAuthContext();

  return (
    <div className={css.container}>
      <Card title="UserManagement" className={css.card}>
        <Space direction="vertical" className={css.space}>
          <Row>
            <Typography.Text>Please login to view the app</Typography.Text>
          </Row>
          <Row>
            <Button type="primary" block onClick={login}>
              Login
            </Button>
          </Row>
        </Space>
      </Card>
    </div>
  );
}

export default LoginPage;
