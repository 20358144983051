import { FetchResult } from "@apollo/client";
import {
  CreateAppRoleMutation,
  CreatePermissionRoleMutation,
  CreateRoleInput,
  CreateRoleMutation,
} from "../../../apollo/customQueries/nested/API";
import { MutationNodeCallback } from "../../../types/types";
import { showError } from "../../../utils/toastNotifications";
import { RoleFormData } from "../types";

export async function createNewRole(
  formData: RoleFormData,
  createRole: MutationNodeCallback<NonNullable<CreateRoleMutation>>,
  createPermissionRole: MutationNodeCallback<NonNullable<CreatePermissionRoleMutation>>,
  createAppRole: MutationNodeCallback<NonNullable<CreateAppRoleMutation>>,
) {
  try {
    const input: CreateRoleInput = {
      name: formData.name ?? "",
      camelName: formData.camelName ?? "",
      description: formData.description ?? "",
    };
    const newRole = await createRole({ variables: { input } });

    const roleID = newRole.data?.createRole?.id;

    if (!roleID) {
      showError("Cannot create new role");
      console.error("Cannot find role ID in the create role response", newRole);
      return;
    }

    const createPermissionRequests: Promise<FetchResult<CreatePermissionRoleMutation>>[] =
      formData.permissions?.map((id) => createPermissionRole({ variables: { input: { permissionID: id, roleID } } })) ?? [];

    const createAppRequests: Promise<FetchResult<CreateAppRoleMutation>>[] =
      formData.apps?.map((id) => createAppRole({ variables: { input: { appID: id, roleID } } })) ?? [];

    await Promise.all([...createPermissionRequests, ...createAppRequests]);

    return true;
  } catch (e) {
    showError("Cannot create new role");
    return false;
  }
}
