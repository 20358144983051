import { gql, useMutation } from "@apollo/client";
import { ApolloMutationTuple } from "../../types/types";
import { CreateUserMutation, ListUsersQuery } from "../customQueries/nested/API";
import { createUser } from "../customQueries/nested/mutations";
import { listUsers } from "../customQueries/nested/queries";
import { listUsersQueryOptions } from "../requestOptions";

const useUserCreate = (): ApolloMutationTuple<CreateUserMutation> =>
  useMutation(gql(createUser), {
    update(cache, { data }) {
      if (data?.createUser) {
        try {
          const options = { ...listUsersQueryOptions, query: gql(listUsers) };
          const cachedRequest = cache.readQuery<ListUsersQuery>(options);
          if (!cachedRequest) {
            return;
          }

          const items = cachedRequest.listUsers?.items?.concat(data.createUser) ?? [];

          cache.writeQuery({
            ...options,
            data: {
              listUsers: {
                ...cachedRequest.listUsers,
                items,
              },
            },
          });
        } catch (err) {
          console.warn("Apollo client cache update failed. Were you updating a query you haven't used yet?", err);
        }
      }
    },
  });

export default useUserCreate;
