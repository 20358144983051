import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { User } from "../../apollo/customQueries/nested/API";
import useAppUserDelete from "../../apollo/user/useAppUserDelete";
import useUserDelete from "../../apollo/user/useUserDelete";
import useUserRoleDelete from "../../apollo/user/useUserRoleDelete";
import { deleteSelectedUser } from "./deleteUser";
import UserModal from "./Form/UserModal";

function UserActions({ user }: { user: User }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteUserMutation] = useUserDelete();
  const [deleteAppUser] = useAppUserDelete();
  const [deleteUserRole] = useUserRoleDelete();

  function showPromiseConfirm(user: User) {
    Modal.confirm({
      title: "Delete user",
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete user ${user.email}?`,
      onOk: () => deleteSelectedUser(user, deleteUserMutation, deleteAppUser, deleteUserRole),
      onCancel() {},
    });
  }

  return (
    <>
      <Button type="link" onClick={() => showPromiseConfirm(user)}>
        Delete
      </Button>
      |
      <Button type="link" onClick={() => setIsModalOpen(true)}>
        Edit
      </Button>
      <UserModal user={user} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
}

export default UserActions;
