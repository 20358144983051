/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
    createUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      preferredName
      apps {
        items {
          id
          appID
          userID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          id
          roleID
          userID
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      preferredName
      apps {
        items {
          id
          appID
          userID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          id
          roleID
          userID
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      preferredName
      apps {
        items {
          id
          appID
          userID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          id
          roleID
          userID
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createApp = /* GraphQL */ `
  mutation CreateApp($input: CreateAppInput!, $condition: ModelAppConditionInput) {
    createApp(input: $input, condition: $condition) {
      id
      name
      description
      users {
        items {
          id
          appID
          userID
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          id
          appID
          roleID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions {
        items {
          id
          name
          appID
          app {
            id
            name
            description

            createdAt
            updatedAt
          }
          roles {
            items {
              id
              roleID
              permissionID
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              permissionID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateApp = /* GraphQL */ `
  mutation UpdateApp($input: UpdateAppInput!, $condition: ModelAppConditionInput) {
    updateApp(input: $input, condition: $condition) {
      id
      name
      description
      users {
        items {
          id
          appID
          userID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          id
          appID
          roleID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions {
        items {
          id
          name
          appID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          roles {
            items {
              id
              roleID
              permissionID
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              permissionID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteApp = /* GraphQL */ `
  mutation DeleteApp($input: DeleteAppInput!, $condition: ModelAppConditionInput) {
    deleteApp(input: $input, condition: $condition) {
      id
      name
      description
      users {
        items {
          id
          appID
          userID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          id
          appID
          roleID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions {
        items {
          id
          name
          appID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          roles {
            items {
              id
              roleID
              permissionID
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              permissionID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole($input: CreateRoleInput!, $condition: ModelRoleConditionInput) {
    createRole(input: $input, condition: $condition) {
      id
      name
      camelName
      description
      users {
        items {
          id
          roleID
          userID
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      apps {
        items {
          id
          appID
          roleID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions {
        items {
          id
          roleID
          permissionID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole($input: UpdateRoleInput!, $condition: ModelRoleConditionInput) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      camelName
      description
      users {
        items {
          id
          roleID
          userID
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      apps {
        items {
          id
          appID
          roleID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions {
        items {
          id
          roleID
          permissionID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole($input: DeleteRoleInput!, $condition: ModelRoleConditionInput) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      camelName
      description
      users {
        items {
          id
          roleID
          userID
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      apps {
        items {
          id
          appID
          roleID
          app {
            id
            name
            description
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions {
        items {
          id
          roleID
          permissionID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPermission = /* GraphQL */ `
  mutation CreatePermission($input: CreatePermissionInput!, $condition: ModelPermissionConditionInput) {
    createPermission(input: $input, condition: $condition) {
      id
      name
      appID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      roles {
        items {
          id
          roleID
          permissionID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          permissionID
          userID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission($input: UpdatePermissionInput!, $condition: ModelPermissionConditionInput) {
    updatePermission(input: $input, condition: $condition) {
      id
      name
      appID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }

            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      roles {
        items {
          id
          roleID
          permissionID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }

            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          permissionID
          userID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePermission = /* GraphQL */ `
  mutation DeletePermission($input: DeletePermissionInput!, $condition: ModelPermissionConditionInput) {
    deletePermission(input: $input, condition: $condition) {
      id
      name
      appID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      roles {
        items {
          id
          roleID
          permissionID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          permissionID
          userID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAppRole = /* GraphQL */ `
  mutation CreateAppRole($input: CreateAppRoleInput!, $condition: ModelAppRoleConditionInput) {
    createAppRole(input: $input, condition: $condition) {
      id
      appID
      roleID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAppRole = /* GraphQL */ `
  mutation UpdateAppRole($input: UpdateAppRoleInput!, $condition: ModelAppRoleConditionInput) {
    updateAppRole(input: $input, condition: $condition) {
      id
      appID
      roleID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppRole = /* GraphQL */ `
  mutation DeleteAppRole($input: DeleteAppRoleInput!, $condition: ModelAppRoleConditionInput) {
    deleteAppRole(input: $input, condition: $condition) {
      id
      appID
      roleID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAppUser = /* GraphQL */ `
  mutation CreateAppUser($input: CreateAppUserInput!, $condition: ModelAppUserConditionInput) {
    createAppUser(input: $input, condition: $condition) {
      id
      appID
      userID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAppUser = /* GraphQL */ `
  mutation UpdateAppUser($input: UpdateAppUserInput!, $condition: ModelAppUserConditionInput) {
    updateAppUser(input: $input, condition: $condition) {
      id
      appID
      userID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppUser = /* GraphQL */ `
  mutation DeleteAppUser($input: DeleteAppUserInput!, $condition: ModelAppUserConditionInput) {
    deleteAppUser(input: $input, condition: $condition) {
      id
      appID
      userID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserRole = /* GraphQL */ `
  mutation CreateUserRole($input: CreateUserRoleInput!, $condition: ModelUserRoleConditionInput) {
    createUserRole(input: $input, condition: $condition) {
      id
      roleID
      userID
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserRole = /* GraphQL */ `
  mutation UpdateUserRole($input: UpdateUserRoleInput!, $condition: ModelUserRoleConditionInput) {
    updateUserRole(input: $input, condition: $condition) {
      id
      roleID
      userID
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserRole = /* GraphQL */ `
  mutation DeleteUserRole($input: DeleteUserRoleInput!, $condition: ModelUserRoleConditionInput) {
    deleteUserRole(input: $input, condition: $condition) {
      id
      roleID
      userID
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPermissionRole = /* GraphQL */ `
  mutation CreatePermissionRole($input: CreatePermissionRoleInput!, $condition: ModelPermissionRoleConditionInput) {
    createPermissionRole(input: $input, condition: $condition) {
      id
      roleID
      permissionID
      permission {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePermissionRole = /* GraphQL */ `
  mutation UpdatePermissionRole($input: UpdatePermissionRoleInput!, $condition: ModelPermissionRoleConditionInput) {
    updatePermissionRole(input: $input, condition: $condition) {
      id
      roleID
      permissionID
      permission {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePermissionRole = /* GraphQL */ `
  mutation DeletePermissionRole($input: DeletePermissionRoleInput!, $condition: ModelPermissionRoleConditionInput) {
    deletePermissionRole(input: $input, condition: $condition) {
      id
      roleID
      permissionID
      permission {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPermissionUser = /* GraphQL */ `
  mutation CreatePermissionUser($input: CreatePermissionUserInput!, $condition: ModelPermissionUserConditionInput) {
    createPermissionUser(input: $input, condition: $condition) {
      id
      permissionID
      userID
      permission {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePermissionUser = /* GraphQL */ `
  mutation UpdatePermissionUser($input: UpdatePermissionUserInput!, $condition: ModelPermissionUserConditionInput) {
    updatePermissionUser(input: $input, condition: $condition) {
      id
      permissionID
      userID
      permission {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePermissionUser = /* GraphQL */ `
  mutation DeletePermissionUser($input: DeletePermissionUserInput!, $condition: ModelPermissionUserConditionInput) {
    deletePermissionUser(input: $input, condition: $condition) {
      id
      permissionID
      userID
      permission {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
