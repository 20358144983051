import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { Role } from "../../apollo/customQueries/nested/API";
import useAppRoleDelete from "../../apollo/role/useAppRoleDelete";
import usePermissionRoleDelete from "../../apollo/role/usePermissionRoleDelete";
import useRoleDelete from "../../apollo/role/useRoleDelete";
import useUserRoleDelete from "../../apollo/user/useUserRoleDelete";
import { deleteSelectedRole } from "./deleteRole";
import RoleModal from "./Form/RoleModal";

function getUserConnectedText(usersConnected: number) {
  switch (usersConnected) {
    case 0:
      return "There are no users";
    case 1:
      return "There is 1 user";
    default:
      return `There are ${usersConnected} users`;
  }
}

function RoleActions({ role }: { role: Role }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteRoleMutation] = useRoleDelete();
  const [deleteAppRole] = useAppRoleDelete();
  const [deletePermissionRole] = usePermissionRoleDelete();
  const [deleteUserRole] = useUserRoleDelete();

  function showPromiseConfirm(role: Role) {
    const usersConnected = role.users?.items?.length ?? 0;
    const userText = getUserConnectedText(usersConnected);
    Modal.confirm({
      title: "Delete role",
      icon: <ExclamationCircleOutlined />,
      content: `${userText} assigned to role "${role.name}". Do you want to delete the role?`,
      onOk: () => deleteSelectedRole(role, deleteRoleMutation, deleteAppRole, deletePermissionRole, deleteUserRole),
      onCancel() {},
    });
  }

  return (
    <>
      <Button type="link" onClick={() => showPromiseConfirm(role)}>
        Delete
      </Button>
      |
      <Button type="link" onClick={() => setIsModalOpen(true)}>
        Edit
      </Button>
      <RoleModal role={role} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
}

export default RoleActions;
