import { gql, useQuery } from "@apollo/client";
import { Table } from "antd";
import React from "react";
import { ListUsersQuery, User, UserRole } from "../../apollo/customQueries/nested/API";
import { listUsers } from "../../apollo/customQueries/nested/queries";
import { listUsersQueryOptions } from "../../apollo/requestOptions";
import UserActions from "./UserActions";

function getRoleName(a: User): string {
  return a?.roles?.items?.[0]?.role?.name ?? "";
}

const columns = [
  {
    title: "Email",
    dataIndex: "email",
    width: "30%",
    key: "email",
    sorter: {
      compare: (a: User, b: User) => ((a?.email ?? "") <= (b?.email ?? "") ? -1 : 1),
    },
  },
  {
    title: "Preferred name",
    key: "preferredName",
    width: "30%",
    dataIndex: "preferredName",
    sorter: {
      compare: (a: User, b: User) => ((a?.preferredName ?? "") <= (b?.preferredName ?? "") ? -1 : 1),
    },
  },
  {
    title: "Role",
    key: "roles",
    width: "20%",
    dataIndex: ["roles", "items"],
    render: (userRoles: UserRole[]) => {
      const roles = userRoles?.map((userRole: UserRole) => userRole.role?.name).join(", ");
      return <> {roles} </>;
    },
    sorter: {
      compare: (a: User, b: User) => (getRoleName(a) <= getRoleName(b) ? -1 : 1),
    },
  },
  {
    width: "20%",
    title: "Action",
    key: "action",
    render: (_: string, user: User) => <UserActions user={user} />,
  },
];

function UserTable() {
  const status = useQuery<ListUsersQuery>(gql(listUsers), listUsersQueryOptions);
  const parsedData: User[] = (status.data?.listUsers?.items as User[]) ?? [];

  return <Table columns={columns} dataSource={parsedData} loading={status.loading} rowKey="email" />;
}

export default UserTable;
