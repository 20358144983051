import { gql, useQuery } from "@apollo/client";

import { Form, FormInstance, Input, Select } from "antd";
import React, { useMemo } from "react";
import { App, ListAppsQuery } from "../../../apollo/customQueries/flat/API";
import { listApps } from "../../../apollo/customQueries/flat/queries";

import { ListRolesQuery, Role, User } from "../../../apollo/customQueries/nested/API";
import { listRoles } from "../../../apollo/customQueries/nested/queries";
import { requestSizeLimit } from "../../../utils/constants";
import { UserFormData } from "../types";

const options = {
  variables: {
    limit: requestSizeLimit,
  },
};

function UserForm({ values, form }: { values?: User; form: FormInstance<User> }) {
  const rolesStatus = useQuery<ListRolesQuery>(gql(listRoles), options);
  const appsStatus = useQuery<ListAppsQuery>(gql(listApps), options);

  const appOptions: App[] = (appsStatus.data?.listApps?.items as App[]) ?? [];
  const roleOptions: Role[] = (rolesStatus.data?.listRoles?.items as Role[]) ?? [];
  const editMode = Boolean(values);

  const initialValues = useMemo(
    () =>
      ({
        ...values,
        role: values?.roles?.items?.[0]?.role?.id,
        apps: values?.apps?.items?.map((item) => item?.app?.id) ?? [],
      } as UserFormData),
    [values],
  );

  return (
    <Form form={form} layout="vertical" name="form_in_modal" initialValues={initialValues}>
      <Form.Item label="Email" name="email">
        <Input disabled={editMode} />
      </Form.Item>
      <Form.Item label="First Name" name="firstName">
        <Input />
      </Form.Item>
      <Form.Item label="Last Name" name="lastName">
        <Input />
      </Form.Item>
      <Form.Item label="Preferred Name" name="preferredName">
        <Input />
      </Form.Item>
      <Form.Item label="Applications" name="apps">
        <Select showSearch allowClear mode="multiple">
          {appOptions.map((option: App) => (
            <Select.Option key={option.id} value={option.id ?? ""}>
              {option.name ?? ""}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Roles" name="role">
        <Select showSearch>
          {roleOptions.map((option: Role) => (
            <Select.Option key={option.id} value={option.id ?? ""}>
              {option.name ?? ""}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
}

export default UserForm;
