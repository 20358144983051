import { Form, Modal } from "antd";
import React, { useState } from "react";
import { Role } from "../../../apollo/customQueries/nested/API";
import useAppRoleCreate from "../../../apollo/role/useAppRoleCreate";
import useAppRoleDelete from "../../../apollo/role/useAppRoleDelete";
import usePermissionRoleCreate from "../../../apollo/role/usePermissionRoleCreate";
import usePermissionRoleDelete from "../../../apollo/role/usePermissionRoleDelete";
import useRoleCreate from "../../../apollo/role/useRoleCreate";
import useRoleUpdate from "../../../apollo/role/useRoleUpdate";
import { applyChanges } from "./applyChanges";
import { createNewRole } from "./createNewRole";
import { RoleForm } from "./RoleForm";

function RoleModal({ role, isModalOpen, setIsModalOpen }: { role?: Role; isModalOpen: boolean; setIsModalOpen: (open: boolean) => void }) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [createRoleMutation] = useRoleCreate();
  const [createPermissionRole] = usePermissionRoleCreate();
  const [createAppRole] = useAppRoleCreate();

  const [updateRoleMutation] = useRoleUpdate();
  const [deleteAppRole] = useAppRoleDelete();
  const [deletePermissionRole] = usePermissionRoleDelete();

  const [form] = Form.useForm();

  async function handleOk() {
    setConfirmLoading(true);
    const formData = form.getFieldsValue();

    const success = role
      ? await applyChanges(role, formData, updateRoleMutation, createAppRole, deleteAppRole, createPermissionRole, deletePermissionRole)
      : await createNewRole(formData, createRoleMutation, createPermissionRole, createAppRole);

    setConfirmLoading(false);
    if (success) {
      form.resetFields();
      setIsModalOpen(false);
    }
  }

  function handleCancel() {
    form.resetFields();
    setIsModalOpen(false);
  }

  return (
    <Modal
      title={role ? `Editing role: ${role.name}` : "Creating new role"}
      visible={isModalOpen}
      destroyOnClose
      maskClosable={false}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      style={{ minWidth: "70%" }}
    >
      <RoleForm values={role} form={form} />
    </Modal>
  );
}

export default RoleModal;
