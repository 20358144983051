import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import React, { createContext, useContext, useEffect, useState } from "react";
import LoginPage from "./login";

interface OAuthUser {
  name: string | undefined;
  email: string | undefined;
  imageUrl: string | undefined;
}

export interface AuthContextValue {
  user: OAuthUser;
  login: () => void;
  logout: () => void;
}

const defaultAuthContext: AuthContextValue = {
  user: { name: undefined, email: undefined, imageUrl: undefined },
  login: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextValue>(defaultAuthContext);

export function useAuthContext(): AuthContextValue {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw Error("useAuthContext must be used inside AuthProvider");
  }
  return ctx;
}

function AuthProvider({ children }: React.PropsWithChildren<{}>) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<OAuthUser>(defaultAuthContext.user);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    async function getCurrentSession() {
      try {
        const session = await Auth.currentSession();
        console.log({ session });

        const token = session.getIdToken();
        const jwtToken = token.getJwtToken();
        const userInfo = token.decodePayload();
        setToken(jwtToken);

        console.log({ userInfo });

        const email = userInfo?.name;
        const name = userInfo?.preferred_username ?? email ?? "Unknown user";

        setUser({ email, name, imageUrl: undefined });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
    getCurrentSession();
  }, []);

  const login = async () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito });
  };

  const logout = () => {
    Auth.signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
      }}
    >
      {loading ? "Loading..." : token ? <>{children}</> : <LoginPage />}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
