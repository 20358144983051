import React from "react";
import { CreateNewButton } from "./CreateNew";
import css from "./index.module.scss";
import UserTable from "./UserTable";

function StriveUsers() {
  return (
    <>
      <div className={css.container}>
        <div className={css.text}>
          <span className={css.title}>Strive Users</span>
          Add Wooga users to Strive and assign them roles
        </div>

        <div className={css.createButton}>
          <CreateNewButton />
        </div>
      </div>

      <UserTable />
    </>
  );
}

export default StriveUsers;
