/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listApps = /* GraphQL */ `
  query ListApps($filter: ModelAppFilterInput, $limit: Int, $nextToken: String) {
    listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listPermissions = /* GraphQL */ `
  query ListPermissions($filter: ModelPermissionFilterInput, $limit: Int, $nextToken: String) {
    listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        appID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
