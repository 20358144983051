import { requestSizeLimit } from "../utils/constants";

export const listUsersQueryOptions = {
  variables: {
    // filter: { game: { eq: game } },
    limit: requestSizeLimit,
  },
};

export const listRolesQueryOptions = {
  variables: {
    // filter: { game: { eq: game } },
    limit: requestSizeLimit,
  },
};
