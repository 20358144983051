import { FetchResult } from "@apollo/client";
import {
  AppRole,
  CreateAppRoleMutation,
  CreatePermissionRoleMutation,
  DeleteAppRoleMutation,
  DeletePermissionRoleMutation,
  Role,
  UpdateRoleMutation,
} from "../../../apollo/customQueries/nested/API";
import { MutationNodeCallback } from "../../../types/types";
import { areSetsEqual, setDifference } from "../../../utils/sets";
import { showError } from "../../../utils/toastNotifications";
import { RoleFormData } from "../types";

async function applyRoleChanges(
  role: Role,
  formData: RoleFormData,
  updateRoleMutation: MutationNodeCallback<NonNullable<UpdateRoleMutation>>,
) {
  if (role.name !== formData.name || role.camelName !== formData.camelName || role.description !== formData.description) {
    const input = {
      id: role.id,
      name: formData.name,
      camelName: formData.camelName,
      description: formData.description,
    };
    await updateRoleMutation({ variables: { input } });
  }
}

async function applyAppsChanges(
  role: Role,
  formData: RoleFormData,
  createAppRole: MutationNodeCallback<NonNullable<CreateAppRoleMutation>>,
  deleteAppRole: MutationNodeCallback<NonNullable<DeleteAppRoleMutation>>,
) {
  const appRoles = (role?.apps?.items ?? []) as AppRole[];
  const currentAppIds = new Set(appRoles?.map((userApp) => userApp?.app?.id) ?? []);
  const requestedAppIds = new Set(formData.apps);

  if (!areSetsEqual(currentAppIds, requestedAppIds)) {
    const createRequests: Promise<FetchResult<CreateAppRoleMutation>>[] = [...requestedAppIds].map((id) =>
      createAppRole({ variables: { input: { appID: id, roleID: role.id ?? "" } } }),
    );

    const deleteRequests: Promise<FetchResult<DeleteAppRoleMutation>>[] = appRoles.map((appRole) =>
      deleteAppRole({ variables: { input: { id: appRole.id } } }),
    );

    await Promise.all([...createRequests, ...deleteRequests]);
  }
}

async function applyPermissionChanges(
  role: Role,
  formData: RoleFormData,
  createPermissionRole: MutationNodeCallback<NonNullable<CreatePermissionRoleMutation>>,
  deletePermissionRole: MutationNodeCallback<NonNullable<DeletePermissionRoleMutation>>,
) {
  const permissionRoles = role?.permissions?.items ?? [];
  const currentPermissionIds = new Set((permissionRoles.map((permissionRole) => permissionRole?.permission?.id) ?? []) as string[]);
  const requestedPermissionIds = new Set(formData.permissions);

  if (!areSetsEqual(currentPermissionIds, requestedPermissionIds)) {
    const permissionIdsToCreate = setDifference(requestedPermissionIds, currentPermissionIds);
    const permissionIdsToRemove = setDifference(currentPermissionIds, requestedPermissionIds);
    const permRolesToRemove = permissionRoles.filter((item) => permissionIdsToRemove.has(item?.permissionID ?? ""));

    const createRequests: Promise<FetchResult<CreatePermissionRoleMutation>>[] = [...permissionIdsToCreate].map((idToCreate) =>
      createPermissionRole({ variables: { input: { permissionID: idToCreate, roleID: role.id ?? "" } } }),
    );

    const deleteRequests: Promise<FetchResult<DeletePermissionRoleMutation>>[] = permRolesToRemove.map((permRole) =>
      deletePermissionRole({ variables: { input: { id: permRole?.id } } }),
    );

    await Promise.all([...createRequests, ...deleteRequests]);
  }
}

export async function applyChanges(
  role: Role,
  formData: RoleFormData,
  updateRoleMutation: MutationNodeCallback<NonNullable<UpdateRoleMutation>>,
  createAppRole: MutationNodeCallback<NonNullable<CreateAppRoleMutation>>,
  deleteAppRole: MutationNodeCallback<NonNullable<DeleteAppRoleMutation>>,
  createPermissionRole: MutationNodeCallback<NonNullable<CreatePermissionRoleMutation>>,
  deletePermissionRole: MutationNodeCallback<NonNullable<DeletePermissionRoleMutation>>,
): Promise<boolean> {
  try {
    await Promise.all([
      applyRoleChanges(role, formData, updateRoleMutation),
      applyAppsChanges(role, formData, createAppRole, deleteAppRole),
      applyPermissionChanges(role, formData, createPermissionRole, deletePermissionRole),
    ]);
    return true;
  } catch (e) {
    showError("Something went wrong. Cannot apply changes to the role");
    return false;
  }
}
