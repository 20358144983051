import { gql, useMutation } from "@apollo/client";
import { Form, Modal } from "antd";
import React, { useState } from "react";
import { User } from "../../../apollo/customQueries/nested/API";
import { updateUser } from "../../../apollo/customQueries/nested/mutations";
import useAppUserCreate from "../../../apollo/user/useAppUserCreate";
import useAppUserDelete from "../../../apollo/user/useAppUserDelete";
import useUserCreate from "../../../apollo/user/useUserCreate";
import useUserRoleCreate from "../../../apollo/user/useUserRoleCreate";
import useUserRoleDelete from "../../../apollo/user/useUserRoleDelete";
import { applyChanges } from "./applyChanges";
import { createNewUser } from "./createNewUser";
import UserForm from "./UserForm";

function UserModal({ user, isModalOpen, setIsModalOpen }: { user?: User; isModalOpen: boolean; setIsModalOpen: (open: boolean) => void }) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [createUserMutation] = useUserCreate();
  const [updateUserMutation] = useMutation(gql(updateUser));
  const [createAppUser] = useAppUserCreate();
  const [deleteAppUser] = useAppUserDelete();
  const [createUserRole] = useUserRoleCreate();
  const [deleteUserRole] = useUserRoleDelete();
  const [form] = Form.useForm();

  async function handleOk() {
    setConfirmLoading(true);
    const formData = form.getFieldsValue();

    const success = user
      ? await applyChanges(user, formData, updateUserMutation, createAppUser, deleteAppUser, createUserRole, deleteUserRole)
      : await createNewUser(formData, createUserMutation, createAppUser, createUserRole);

    setConfirmLoading(false);
    if (success) {
      setIsModalOpen(false);
    }
  }

  function handleCancel() {
    form.resetFields();
    setIsModalOpen(false);
  }

  return (
    <Modal
      title={user ? `Editing user: ${user.preferredName}` : "Creating new user"}
      visible={isModalOpen}
      destroyOnClose
      maskClosable={false}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <UserForm values={user} form={form} />
    </Modal>
  );
}

export default UserModal;
