import { gql, useMutation } from "@apollo/client";
import { ApolloMutationTuple } from "../../types/types";
import { CreateRoleMutation, ListRolesQuery } from "../customQueries/nested/API";
import { createRole } from "../customQueries/nested/mutations";
import { listRoles } from "../customQueries/nested/queries";
import { listRolesQueryOptions } from "../requestOptions";

const useRoleCreate = (): ApolloMutationTuple<CreateRoleMutation> =>
  useMutation(gql(createRole), {
    update(cache, { data }) {
      if (data?.createRole) {
        try {
          const options = { ...listRolesQueryOptions, query: gql(listRoles) };
          const cachedRequest = cache.readQuery<ListRolesQuery>(options);
          if (!cachedRequest) {
            return;
          }

          const items = cachedRequest.listRoles?.items?.concat(data.createRole) ?? [];

          cache.writeQuery({
            ...options,
            data: {
              listRoles: {
                ...cachedRequest.listRoles,
                items,
              },
            },
          });
        } catch (err) {
          console.warn("Apollo client cache update failed. Were you updating a query you haven't used yet?", err);
        }
      }
    },
  });

export default useRoleCreate;
