import { FetchResult } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import {
  CreateAppUserMutation,
  CreateUserInput,
  CreateUserMutation,
  CreateUserRoleMutation
} from "../../../apollo/customQueries/nested/API";
import { MutationNodeCallback } from "../../../types/types";
import { showError } from "../../../utils/toastNotifications";
import { UserFormData } from "../types";

export async function createNewUser(
  formData: UserFormData,
  createUser: MutationNodeCallback<NonNullable<CreateUserMutation>>,
  createAppUser: MutationNodeCallback<NonNullable<CreateAppUserMutation>>,
  createUserRole: MutationNodeCallback<NonNullable<CreateUserRoleMutation>>,
) {
  if (!formData.email) {
    showError("Cannot create user: email is undefined");
    return false;
  }
  try {
    const input: CreateUserInput = {
      id: uuidv4(),
      email: formData.email ?? "",
      firstName: formData.firstName ?? "",
      lastName: formData.lastName ?? "",
      preferredName: formData.preferredName ?? "",
    };
    await createUser({ variables: { input } });

    const createAppUserRequests: Promise<FetchResult<CreateAppUserMutation>>[] =
      formData.apps?.map((id) => createAppUser({ variables: { input: { appID: id, userID: formData.email ?? "" } } })) ?? [];

    const createUserRoleRequest: Promise<FetchResult<CreateUserRoleMutation>>[] = formData.role
      ? [createUserRole({ variables: { input: { roleID: formData.role, userID: formData.email ?? "" } } })]
      : [];
    await Promise.all([...createAppUserRequests, ...createUserRoleRequest]);

    return true;
  } catch (e) {
    showError("Cannot create new user. Make sure that the email is not already taken");
    return false;
  }
}
