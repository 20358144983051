import { SafetyOutlined, TeamOutlined } from "@ant-design/icons";
import React from "react";

export const navigationRoutes = [
  {
    name: "Strive Users",
    icon: <TeamOutlined />,
    url: "/users",
  },
  {
    name: "Roles",
    icon: <SafetyOutlined />,
    url: "/roles",
  },
  // {
  //   name: "Workflow Permissions",
  //   icon: <ControlOutlined />,
  //   url: "/permissions",
  // },
];
