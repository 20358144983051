import { Transfer } from "antd";
import { TransferItem, TransferProps } from "antd/lib/transfer";
import React, { useState } from "react";

interface Props extends TransferProps<TransferItem> {
  value?: string[];
  onChange?: (value: string[]) => void;
}

function TransferInput({ value, onChange, ...props }: Props) {
  const [targetKeys, setTargetKeys] = useState<string[]>(value ?? []);

  const handleChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
    onChange?.(nextTargetKeys);
  };

  return <Transfer {...props} onChange={handleChange} targetKeys={targetKeys} />;
}

export default TransferInput;
