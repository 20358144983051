import { LeftSquareOutlined, RightSquareOutlined } from "@ant-design/icons";
import { Layout, Menu, Row, Col } from "antd";
import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Header from "./header";

import css from "./index.module.scss";

export type NavigationItem = {
  name: string;
  icon: any;
  url: string;
  children?: NavigationItem[];
};

export type UserData = {
  name: string;
  isLoggedIn: boolean;
  imageUrl: string;
  login: Function;
  logout: Function;
};

interface DashboardProps {
  navigation: NavigationItem[];
}

function Dashboard({ navigation, children }: React.PropsWithChildren<DashboardProps>) {
  const [collapsed, setCollapsed] = useState(false);

  const location = useLocation();
  const CollapseIcon = collapsed ? RightSquareOutlined : LeftSquareOutlined;

  return (
    <Layout className={css.dashboardLayout}>
      <Layout.Sider trigger={null} collapsible collapsed={collapsed} className={collapsed ? "" : css.expandedSidebar}>
        <div className={css.appLogo}>
          <span className={css.left}>{collapsed ? "U" : "User"}</span>
          <span className={css.right}>{collapsed ? "M" : "Management"}</span>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={navigation
            .filter(({ url }) => (url === "/" ? url === location.pathname : location.pathname.includes(url)))
            .map((route) => route.url)}
        >
          {navigation.map(({ url, name, icon }) => (
            <Menu.Item key={url} icon={icon}>
              <Link to={url}>{name}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Layout.Sider>
      <Layout className={css.siteLayout}>
        <Layout.Header className={css.header}>
          <Row gutter={16}>
            <Col flex={1}>
              <CollapseIcon className={css.trigger} onClick={() => setCollapsed(!collapsed)} />
            </Col>
            <Col flex={23}>
              <Header />
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content className={css.content}>{children}</Layout.Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
