import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { Amplify, Auth } from "aws-amplify";
import { AuthOptions, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import omitDeep from "omit-deep-lodash";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AuthProvider from "./auth/provider";
import awsExports from "./aws-exports";
import Dashboard from "./dashboard";
import { HeaderProvider } from "./dashboard/header/context";
import { navigationRoutes } from "./dashboard/navigation";
import Roles from "./pages/Roles";
import StriveUsers from "./pages/StriveUsers";
// todo try to import it from here. Right now there are type errors
// import { apolloClient, link } from "@wooga/user-management/helpers/apolloClient";

const appUrl = window.location.origin;

const augmentedConfig = {
  ...awsExports,
  oauth: {
    domain: awsExports.oauth.domain,
    scope: awsExports.oauth.scope ?? ["openid"],
    redirectSignIn: awsExports.oauth.redirectSignIn ?? appUrl,
    redirectSignOut: awsExports.oauth.redirectSignOut ?? `${appUrl}/`,
    responseType: awsExports.oauth.responseType ?? "code",
  },
};

Amplify.configure({
  ...augmentedConfig,
  Auth: {
    region: awsExports.aws_cognito_region,
    userPoolId: awsExports.aws_user_pools_id,
    userPoolWebClientId: awsExports.aws_user_pools_web_client_id,
    oauth: augmentedConfig.oauth,
  },
});

const config = Auth.configure(augmentedConfig);
console.log({ config });

const onGraphQLError = onError(({ graphQLErrors, operation, networkError }) => {
  graphQLErrors?.forEach(({ message }) => {
    console.error("GQL Error", message);
  });
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ["__typename"];
  const def = getMainDefinition(operation.query);
  if (def && "operation" in def && def.operation === "mutation") {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

export const apolloConfig = {
  url: augmentedConfig.aws_appsync_graphqlEndpoint,
  region: augmentedConfig.aws_project_region,
  auth: {
    type: augmentedConfig.aws_appsync_authenticationType,
    jwtToken: async () => {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      return `Bearer ${token}`;
    }
  } as AuthOptions,
};

const link = ApolloLink.from([
  onGraphQLError as unknown as ApolloLink,
  cleanTypenameLink,
  createAuthLink(apolloConfig) as unknown as ApolloLink,
  createSubscriptionHandshakeLink(apolloConfig) as unknown as ApolloLink,
]);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ApolloProvider client={client}>
          <HeaderProvider>
            <Dashboard navigation={navigationRoutes}>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/users" />
                </Route>
                <Route path="/users" component={StriveUsers} />
                <Route path="/roles" component={Roles} />
                {/*<Route path="/permissions" component={WorkflowPermissions} />*/}
              </Switch>
            </Dashboard>
          </HeaderProvider>
        </ApolloProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
