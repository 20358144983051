export const colors = ["cyan", "magenta", "blue", "purple", "gold", "green", "red", "volcano", "geekblue", "orange", "lime"];

// this hash function is taken from https://github.com/darkskyapp/string-hash/
function hash(str: string): number {
  let hash = 5381;
  let i = str.length;

  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
}

export function getColorByString(value: string | undefined): string {
  if (!value?.length) {
    return colors[0];
  }
  const index = hash(value) % colors.length;
  return colors[index];
}
