/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($email: ID!) {
    getUser(email: $email) {
      id
      email
      firstName
      lastName
      preferredName
      apps {
        items {
          id
          appID
          userID
          app {
            id
            name
            description
            users {
              nextToken
            }
            roles {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            apps {
              nextToken
            }
            roles {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          id
          roleID
          userID
          user {
            id
            email
            firstName
            lastName
            preferredName
            apps {
              nextToken
            }
            roles {
              nextToken
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            users {
              nextToken
            }
            apps {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $email: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApp = /* GraphQL */ `
  query GetApp($id: ID!) {
    getApp(id: $id) {
      id
      name
      description
      users {
        items {
          id
          appID
          userID
          app {
            id
            name
            description
            users {
              nextToken
            }
            roles {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            apps {
              nextToken
            }
            roles {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      roles {
        items {
          id
          appID
          roleID
          app {
            id
            name
            description
            users {
              nextToken
            }
            roles {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            users {
              nextToken
            }
            apps {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions {
        items {
          id
          name
          appID
          app {
            id
            name
            description
            users {
              nextToken
            }
            roles {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          roles {
            items {
              id
              roleID
              permissionID
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              permissionID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listApps = /* GraphQL */ `
  query ListApps(
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            roles {
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      camelName
      description
      users {
        items {
          id
          roleID
          userID
          user {
            id
            email
            firstName
            lastName
            preferredName
            apps {
              nextToken
            }
            roles {
              nextToken
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            users {
              nextToken
            }
            apps {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      apps {
        items {
          id
          appID
          roleID
          app {
            id
            name
            description
            users {
              nextToken
            }
            roles {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            users {
              nextToken
            }
            apps {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      permissions {
        items {
          id
          roleID
          permissionID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            roles {
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            users {
              nextToken
            }
            apps {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPermission = /* GraphQL */ `
  query GetPermission($id: ID!) {
    getPermission(id: $id) {
      id
      name
      appID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            roles {
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      roles {
        items {
          id
          roleID
          permissionID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            roles {
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          role {
            id
            name
            camelName
            description
            users {
              nextToken
            }
            apps {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          permissionID
          userID
          permission {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            roles {
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          user {
            id
            email
            firstName
            lastName
            preferredName
            apps {
              nextToken
            }
            roles {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppRole = /* GraphQL */ `
  query GetAppRole($id: ID!) {
    getAppRole(id: $id) {
      id
      appID
      roleID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            roles {
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAppRoles = /* GraphQL */ `
  query ListAppRoles(
    $filter: ModelAppRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appID
        roleID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        role {
          id
          name
          camelName
          description
          users {
            items {
              id
              roleID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          apps {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              roleID
              permissionID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppUser = /* GraphQL */ `
  query GetAppUser($id: ID!) {
    getAppUser(id: $id) {
      id
      appID
      userID
      app {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            roles {
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAppUsers = /* GraphQL */ `
  query ListAppUsers(
    $filter: ModelAppUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appID
        userID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        user {
          id
          email
          firstName
          lastName
          preferredName
          apps {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              roleID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserRole = /* GraphQL */ `
  query GetUserRole($id: ID!) {
    getUserRole(id: $id) {
      id
      roleID
      userID
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserRoles = /* GraphQL */ `
  query ListUserRoles(
    $filter: ModelUserRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleID
        userID
        user {
          id
          email
          firstName
          lastName
          preferredName
          apps {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              roleID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        role {
          id
          name
          camelName
          description
          users {
            items {
              id
              roleID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          apps {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              roleID
              permissionID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPermissionRole = /* GraphQL */ `
  query GetPermissionRole($id: ID!) {
    getPermissionRole(id: $id) {
      id
      roleID
      permissionID
      permission {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      role {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPermissionRoles = /* GraphQL */ `
  query ListPermissionRoles(
    $filter: ModelPermissionRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissionRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleID
        permissionID
        permission {
          id
          name
          appID
          app {
            id
            name
            description
            users {
              nextToken
            }
            roles {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          roles {
            items {
              id
              roleID
              permissionID
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              permissionID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        role {
          id
          name
          camelName
          description
          users {
            items {
              id
              roleID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          apps {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              roleID
              permissionID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPermissionUser = /* GraphQL */ `
  query GetPermissionUser($id: ID!) {
    getPermissionUser(id: $id) {
      id
      permissionID
      userID
      permission {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        email
        firstName
        lastName
        preferredName
        apps {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPermissionUsers = /* GraphQL */ `
  query ListPermissionUsers(
    $filter: ModelPermissionUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissionUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        permissionID
        userID
        permission {
          id
          name
          appID
          app {
            id
            name
            description
            users {
              nextToken
            }
            roles {
              nextToken
            }
            permissions {
              nextToken
            }
            createdAt
            updatedAt
          }
          roles {
            items {
              id
              roleID
              permissionID
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              permissionID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        user {
          id
          email
          firstName
          lastName
          preferredName
          apps {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              roleID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const appByName = /* GraphQL */ `
  query AppByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        users {
          items {
            id
            appID
            userID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        roles {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            name
            appID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            roles {
              nextToken
            }
            users {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roleByName = /* GraphQL */ `
  query RoleByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roleByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roleByCamelName = /* GraphQL */ `
  query RoleByCamelName(
    $camelName: String
    $sortDirection: ModelSortDirection
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roleByCamelName(
      camelName: $camelName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        camelName
        description
        users {
          items {
            id
            roleID
            userID
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        apps {
          items {
            id
            appID
            roleID
            app {
              id
              name
              description
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        permissions {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const permissionByName = /* GraphQL */ `
  query PermissionByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    permissionByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const permissionByAppId = /* GraphQL */ `
  query PermissionByAppId(
    $appID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    permissionByAppId(
      appID: $appID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        appID
        app {
          id
          name
          description
          users {
            items {
              id
              appID
              userID
              createdAt
              updatedAt
            }
            nextToken
          }
          roles {
            items {
              id
              appID
              roleID
              createdAt
              updatedAt
            }
            nextToken
          }
          permissions {
            items {
              id
              name
              appID
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        roles {
          items {
            id
            roleID
            permissionID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            role {
              id
              name
              camelName
              description
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            permissionID
            userID
            permission {
              id
              name
              appID
              createdAt
              updatedAt
            }
            user {
              id
              email
              firstName
              lastName
              preferredName
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
