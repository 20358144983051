import { gql, useMutation } from "@apollo/client";
import { ApolloMutationTuple } from "../../types/types";
import { DeleteUserMutation } from "../customQueries/nested/API";
import { deleteUser } from "../customQueries/nested/mutations";

const useUserDelete = (): ApolloMutationTuple<DeleteUserMutation> =>
  useMutation(gql(deleteUser), {
    update(cache, { data }) {
      if (data?.deleteUser) {
        try {
          const identifier = cache.identify(data.deleteUser);

          if (identifier) {
            cache.evict({ id: identifier });
          }
        } catch (err) {
          console.warn("Apollo client cache update failed. Were you updating a query you haven't used yet?", err);
        }
      }
    },
  });

export default useUserDelete;
