import { notification } from "antd";

export const showWarning = (description: string, title?: string) => {
  notification.warning({
    message: title ?? "Warning",
    duration: 0,
    description,
  });
};

export const showError = (description: string, title?: string) => {
  notification.error({
    message: title ?? "Error",
    duration: 0,
    description,
  });
};

export const showInfo = (description: string, title?: string) => {
  notification.error({
    message: title ?? "Info",
    description,
  });
};
