import React, { createContext, useContext, useState } from "react";

enum Game { // todo it will be replaced with Amplify-generated Game entity when it will be added to the gql schema
  Switchcraft = "Switchcraft",
  GhostDetective = "GhostDetective",
}

export const games = Object.values(Game);

export interface HeaderContextValue {
  game?: Game;
  setGame: (game: Game | (() => Game)) => void;
}
const HeaderContext = createContext<HeaderContextValue>({} as HeaderContextValue);

export function useHeaderContext(): HeaderContextValue {
  const ctx = useContext(HeaderContext);

  if (!ctx) {
    throw Error("useDocumentContext must be used inside DocumentProvider");
  }
  return ctx;
}

export function HeaderProvider({ children }: React.PropsWithChildren<{}>) {
  const [game, setGame] = useState(Game.Switchcraft);
  return (
    <HeaderContext.Provider
      value={{
        game,
        setGame,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}
