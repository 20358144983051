import React from "react";
import CreateNewButton from "./CreateNew";
import css from "./index.module.scss";
import RoleTable from "./RoleTable";

function Roles() {
  return (
    <>
      <div className={css.container}>
        <div className={css.text}>
          <span className={css.title}>Roles</span>
          Create roles and change role permissions
        </div>

        <div className={css.createButton}>
          <CreateNewButton />
        </div>
      </div>

      <RoleTable />
    </>
  );
}

export default Roles;
