import { gql, useMutation } from "@apollo/client";
import { ApolloMutationTuple } from "../../types/types";
import { DeleteRoleMutation } from "../customQueries/nested/API";
import { deleteRole } from "../customQueries/nested/mutations";

const useRoleDelete = (): ApolloMutationTuple<DeleteRoleMutation> =>
  useMutation(gql(deleteRole), {
    update(cache, { data }) {
      if (data?.deleteRole) {
        try {
          const identifier = cache.identify(data.deleteRole);

          if (identifier) {
            cache.evict({ id: identifier });
          }
        } catch (err) {
          console.warn("Apollo client cache update failed. Were you updating a query you haven't used yet?", err);
        }
      }
    },
  });

export default useRoleDelete;
