import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";

import UserModal from "./Form/UserModal";

export function CreateNewButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        <PlusOutlined />
        Create new
      </Button>
      <UserModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
}
