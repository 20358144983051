import { FetchResult } from "@apollo/client";
import {
  AppUser,
  DeleteAppUserMutation,
  DeleteUserMutation,
  DeleteUserRoleMutation,
  User,
  UserRole
} from "../../apollo/customQueries/nested/API";
import { MutationNodeCallback } from "../../types/types";

export async function deleteSelectedUser(
  user: User,
  deleteUserMutation: MutationNodeCallback<NonNullable<DeleteUserMutation>>,
  deleteAppUser: MutationNodeCallback<NonNullable<DeleteAppUserMutation>>,
  deleteUserRole: MutationNodeCallback<NonNullable<DeleteUserRoleMutation>>,
): Promise<void> {
  if (!user?.email) {
    return Promise.resolve();
  }
  try {
    // delete appUser connections
    const appUsers = (user.apps?.items ?? []) as AppUser[];
    const deleteAppUserRequests: Promise<FetchResult<DeleteAppUserMutation>>[] = appUsers.map(({ id }) =>
      deleteAppUser({ variables: { input: { id } } }),
    );

    // delete userRole connections
    const userRoles = (user?.roles?.items ?? []) as UserRole[];

    const deleteUserRoleRequests: Promise<FetchResult<DeleteUserRoleMutation>>[] =
      userRoles.map(({ id }) => deleteUserRole({ variables: { input: { id } } })) ?? [];

    await Promise.all([...deleteAppUserRequests, ...deleteUserRoleRequests]);

    // finally, delete user
    await deleteUserMutation({ variables: { input: { email: user.email ?? "" } } });

    return Promise.resolve();
  } catch (e) {
    console.log("Failed to delete user:", e);
    return Promise.reject();
  }
}
