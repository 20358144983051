import { gql, useQuery } from "@apollo/client";
import { Form, FormInstance, Input, Select } from "antd";
import React, { useMemo } from "react";

import { App, ListAppsQuery, ListPermissionsQuery, Permission } from "../../../apollo/customQueries/flat/API";
import { listApps, listPermissions } from "../../../apollo/customQueries/flat/queries";
import { Role } from "../../../apollo/customQueries/nested/API";
import TransferInput from "../../../dashboard/customAnt/TransferFormItem";
import { requestSizeLimit } from "../../../utils/constants";
import { RoleFormData } from "../types";

const options = {
  variables: {
    limit: requestSizeLimit,
  },
};

export function RoleForm({ values, form }: { values?: Role; form: FormInstance<RoleFormData> }) {
  const permissionStatus = useQuery<ListPermissionsQuery>(gql(listPermissions), options);
  const permissionOptions: Permission[] = (permissionStatus.data?.listPermissions?.items as Permission[]) ?? [];

  const appsStatus = useQuery<ListAppsQuery>(gql(listApps), options);
  const appOptions: App[] = (appsStatus.data?.listApps?.items as App[]) ?? [];

  const initialValues = useMemo(
    () =>
      ({
        ...values,
        permissions: values?.permissions?.items?.map((item) => item?.permission?.id) ?? [],
        apps: values?.apps?.items?.map((item) => item?.app?.id) ?? [],
      } as RoleFormData),
    [values],
  );
  const transferDataSource =
    permissionOptions?.map((item) => ({
      key: item.id,
      title: item.name,
      description: item.name,
    })) ?? [];

  return (
    <Form form={form} layout="vertical" name="form_in_modal" initialValues={initialValues}>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Camel Name" name="camelName">
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea rows={2} />
      </Form.Item>
      <Form.Item label="Applications" name="apps">
        <Select showSearch allowClear mode="multiple">
          {appOptions.map((option: App) => (
            <Select.Option key={option.id} value={option.id ?? ""}>
              {option.name ?? ""}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Role permissions" name="permissions">
        <TransferInput
          dataSource={transferDataSource}
          titles={["Available permissions", "Role permissions"]}
          listStyle={{
            width: "50%",
            height: 300,
          }}
          showSearch
          render={(item) => item?.title ?? ""}
        />
      </Form.Item>
    </Form>
  );
}
