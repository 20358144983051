import { gql, useQuery } from "@apollo/client";
import { Table, Tag } from "antd";
import React from "react";
import { ListRolesQuery, PermissionRole, Role } from "../../apollo/customQueries/nested/API";
import { listRoles } from "../../apollo/customQueries/nested/queries";
import { listRolesQueryOptions } from "../../apollo/requestOptions";
import { getColorByString } from "../../utils/getColorByString";
import { localeSort } from "../../utils/sort";
import RoleActions from "./RoleActions";

const columns = [
  {
    title: "Role",
    dataIndex: "name",
    key: "name",
    width: "30%",
    sorter: {
      compare: (a: Role, b: Role) => a?.name?.localeCompare(b?.name ?? "") ?? 0,
    },
  },
  {
    title: "Role permissions",
    key: "permissions",
    dataIndex: ["permissions", "items"],
    render: (permissionRoles: PermissionRole[] | undefined) => (
      <>
        {localeSort(permissionRoles ?? [], (a) => a?.permission?.name ?? "")?.map((permissionRole: PermissionRole) => {
          const permission = permissionRole?.permission?.name ?? "";
          const color = getColorByString(permission);

          return (
            <Tag color={color} key={permission}>
              {permission}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: "Action",
    key: "action",
    width: "20%",
    render: (_: string, role: Role) => <RoleActions role={role} />,
  },
];

function RoleTable() {
  const status = useQuery<ListRolesQuery>(gql(listRoles), listRolesQueryOptions);
  const parsedData: Role[] = (status.data?.listRoles?.items as Role[]) ?? [];

  return <Table columns={columns} dataSource={parsedData} loading={status.loading} rowKey="id" />;
}

export default RoleTable;
